import moment from 'moment';
import React, { FC } from 'react';
import { View, Views } from 'react-big-calendar';
import styles from './MyCalendarNav.module.css'
import { useWindowWidth } from '../../hooks/useWindowWidth';
import MyCalendarNavDesktop from './components/MyCalendarNavDesktop/MyCalendarNavDesktop';
import MyCalendarNavMobile from './components/MyCalendarNavMobile/MyCalendarNavMobile';
interface MyCalendarNavProps {
    view: View;
    currentDate: Date;
    setCurrentDate: (date: Date) => void;
    isMobileVersion: boolean
}

const MyCalendarNav: FC<MyCalendarNavProps> = ({ view, currentDate, setCurrentDate ,isMobileVersion}) => {
  
    return (
        <>
            {!isMobileVersion ?
                <MyCalendarNavDesktop
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    view={view}
                />
                :
                <MyCalendarNavMobile
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                />
            }
        </>
    );
};

export default MyCalendarNav;

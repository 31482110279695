// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyButton_button__nJ5hE {
    width: 310px;
    height: 40px;
    min-height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #F0830E;
    border-radius: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
}`, "",{"version":3,"sources":["webpack://./src/UI/MyButton/MyButton.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".button {\n    width: 310px;\n    height: 40px;\n    min-height: 40px;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n    background: #F0830E;\n    border-radius: 10px;\n    font-family: 'Roboto';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 18px;\n    line-height: 21px;\n    color: #FFFFFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `MyButton_button__nJ5hE`
};
export default ___CSS_LOADER_EXPORT___;

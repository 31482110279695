import { create } from "zustand";
import { ILocation } from "../../../models/location";
import { LocationManagerService } from "../api/LocationManagerService";

interface LocationsState {
  locationName: string;
  setLocationName: (locationName: string) => void;
  locationUrl: string;
  setLocationUrl: (locationUrl: string) => void;
  editMode: boolean;
  setEditMode: (editMode: boolean) => void;
  fetchLocations: () => void;
  locations: ILocation[];
  changeVisible: (id: number) => void;
  createLocation: () => void;
  clearFields: () => void;
  deleteLocation: (id: number) => void;
  changeOrder: (id: number, order: number) => void;
  editLocation: () => void;
  editId: number;
  setEditId: (editId: number) => void;
}

const useLocationStore = create<LocationsState>((set, get) => ({
  locationName: '',
  setLocationName: (locationName: string) => set({ locationName }),

  locationUrl: '',
  setLocationUrl: (locationUrl: string) => set({ locationUrl }),

  editMode: false,
  setEditMode: (editMode: boolean) => set({ editMode }),

  locations: [],
  fetchLocations: async () => {
    try {
      const { data } = await LocationManagerService.getLocations();
      set({ locations: data })
    } catch (error) {
      console.log(error)
    }
  },

  changeVisible: async (id: number) => {
    const { fetchLocations } = get();
    try {
      await LocationManagerService.changeVisible(id);
      await fetchLocations()
    } catch (error) {
      console.log(error)
    }
  },

  createLocation: async () => {
    const { locationName, locationUrl, fetchLocations, clearFields } = get();
    try {
      await LocationManagerService.createLocation({
        locationName, locationUrl
      });
      await fetchLocations()
      await clearFields()
    } catch (error) {
      console.log(error)
    }
  },

  clearFields: () => {
    set({ locationName: '', locationUrl: '' });
  },

  deleteLocation: async (id: number) => {
    const { fetchLocations } = get();
    try {
      await LocationManagerService.deleteLocation(id);
      await fetchLocations()
    } catch (error) {
      console.log(error)
    }
  },

  changeOrder: async (id: number, order: number) => {
    const { fetchLocations } = get();
    try {
      await LocationManagerService.changeOrder(id, order);
      await fetchLocations()

    } catch (error) {
      console.log(error)
    }
  },

  editLocation: async () => {
    const { locationName, locationUrl, fetchLocations, editId } = get();
    try {
      await LocationManagerService.changeLocation({
        locationName, locationUrl, id: editId
      });
      await fetchLocations()
    } catch (error) {
      console.log(error)
    }
  },

  editId: 0,
  setEditId: (editId: number) => set({ editId }),
}));

export default useLocationStore;

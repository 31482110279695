// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LocationMenu_main__zhcqw{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 30px;
}

.LocationMenu_button__jXoIs{
    width: 140px;
}

.LocationMenu_buttonRow__eJUjF{
    display: flex;
    flex-direction: row;
    column-gap: 30px;
}


@media(max-width:750px) {
    .LocationMenu_input__mPxVt{
        width: 200px !important;
        height: 35px !important;
    }
    .LocationMenu_button__jXoIs{
        width: 120px;
        height: 35px ;
        min-height:35px ;
    }
}


@media(max-width:470px) {
    .LocationMenu_input__mPxVt{
        width: 100% !important;
        max-width: 100% !important;
        height: 35px !important;
    }
    .LocationMenu_button__jXoIs{
        width: 120px;
        height: 35px ;
        min-height:35px ;
    }
    .LocationMenu_main__zhcqw{
        row-gap: 25px;
    }.LocationMenu_buttonRow__eJUjF{
        width: 100%;
    }
    .LocationMenu_button__jXoIs{
        width: 45%;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/LocationManager/components/SearchMenu/LocationMenu.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;;AAGA;IACI;QACI,uBAAuB;QACvB,uBAAuB;IAC3B;IACA;QACI,YAAY;QACZ,aAAa;QACb,gBAAgB;IACpB;AACJ;;;AAGA;IACI;QACI,sBAAsB;QACtB,0BAA0B;QAC1B,uBAAuB;IAC3B;IACA;QACI,YAAY;QACZ,aAAa;QACb,gBAAgB;IACpB;IACA;QACI,aAAa;IACjB,CAAC;QACG,WAAW;IACf;IACA;QACI,UAAU;IACd;AACJ","sourcesContent":[".main{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    flex-wrap: wrap;\n    row-gap: 30px;\n}\n\n.button{\n    width: 140px;\n}\n\n.buttonRow{\n    display: flex;\n    flex-direction: row;\n    column-gap: 30px;\n}\n\n\n@media(max-width:750px) {\n    .input{\n        width: 200px !important;\n        height: 35px !important;\n    }\n    .button{\n        width: 120px;\n        height: 35px ;\n        min-height:35px ;\n    }\n}\n\n\n@media(max-width:470px) {\n    .input{\n        width: 100% !important;\n        max-width: 100% !important;\n        height: 35px !important;\n    }\n    .button{\n        width: 120px;\n        height: 35px ;\n        min-height:35px ;\n    }\n    .main{\n        row-gap: 25px;\n    }.buttonRow{\n        width: 100%;\n    }\n    .button{\n        width: 45%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `LocationMenu_main__zhcqw`,
	"button": `LocationMenu_button__jXoIs`,
	"buttonRow": `LocationMenu_buttonRow__eJUjF`,
	"input": `LocationMenu_input__mPxVt`
};
export default ___CSS_LOADER_EXPORT___;

import { AxiosResponse } from "axios";
import $api from "../../../app/api/http";
import { ILocation } from "../../../models/location";
import { IGroup } from "../../../models/group";
 export interface CreateLocationDto {
    locationName: string;
    locationUrl: string;
}
export interface ChangeLocationDto extends CreateLocationDto {
    id: number
} /**/
export class LocationManagerService {
   static async getLocations(): Promise<AxiosResponse<ILocation[]>> {
        return $api.get('/locations')
    }

    static async createLocation(dto: CreateLocationDto): Promise<AxiosResponse<ILocation[]>> {
        return $api.post('/locations', dto)
    }

    static async deleteLocation(id: number): Promise<AxiosResponse> {
        return $api.delete('/locations/' + id)
    }

    static async changeVisible(id: number): Promise<AxiosResponse> {
        return $api.put('/locations/' + id + '/visible')
    }

    static async changeOrder(id: number, order: number): Promise<AxiosResponse> {
        return $api.put('/locations/' + id + '/order', { order })
    }

    static async changeLocation(dto: ChangeLocationDto ): Promise<AxiosResponse> {
        return $api.put('/locations/' , dto)
    } 
}
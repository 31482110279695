import React, { FC, useRef } from 'react';
import moment from 'moment';
import styles from './MyCalendarNavDesktop.module.css';
import { View, Views } from 'react-big-calendar';

interface MyCalendarNavDesktopProps {
    currentDate: Date;
    view: View;
    setCurrentDate: (date: Date) => void;
}

const MyCalendarNavDesktop: FC<MyCalendarNavDesktopProps> = ({ currentDate, view, setCurrentDate }) => {
    const goToPrevious = () => {
        const prevDate = moment(currentDate).subtract(1, view === Views.MONTH ? 'month' : 'week').toDate();
        setCurrentDate(prevDate);
    };

    const goToNext = () => {
        const nextDate = moment(currentDate).add(1, view === Views.MONTH ? 'month' : 'week').toDate();
        setCurrentDate(nextDate);
    };




    const clickTimeout = useRef<NodeJS.Timeout | null>(null);

    const handleClick = () => {
        if (clickTimeout.current) {
            clearTimeout(clickTimeout.current);
            clickTimeout.current = null;
        } else {
            clickTimeout.current = setTimeout(() => {
                console.log("Single click detected!");
                clickTimeout.current = null;
            }, 200); // Задержка для определения двойного клика
        }
    };

    const handleDoubleClick = () => {
        if (clickTimeout.current) {
            clearTimeout(clickTimeout.current);
            clickTimeout.current = null;
        }
        const now = new Date
        if (new Date(currentDate).toDateString() !== new Date().toDateString()) {
            setCurrentDate(now);
            console.log(new Date(currentDate));
            console.log(now);
        }
        
        
       
    };


    return (
        <div className={styles.main}>
            <button onClick={goToPrevious} className={styles.prev}>
                <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.689427 2.00238L0.689434 2.00237L1.38153 1.31028C1.58925 1.10255 1.9259 1.10216 2.13411 1.30939L6.85897 6.0121L7.00006 6.15253L7.14115 6.0121L11.866 1.30939C12.0742 1.10216 12.4108 1.10255 12.6186 1.31028L13.3106 2.00237C13.5187 2.21045 13.5187 2.54779 13.3107 2.75584L13.3106 2.75585L7.37682 8.68973C7.37681 8.68974 7.37681 8.68974 7.37681 8.68974C7.16873 8.8978 6.83137 8.89779 6.6233 8.68973L0.689435 2.75584C0.48135 2.54776 0.481361 2.21042 0.689427 2.00238Z" fill="#F0830E" stroke="white" strokeWidth="0.4" />
                </svg>
                Zurück
            </button>
            <span
                className={styles.month}
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
            >
                {moment(currentDate).format('MMMM YYYY')}
            </span>

            <button onClick={goToNext} className={styles.next}>
                Weiter
                <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.689427 2.00238L0.689434 2.00237L1.38153 1.31028C1.58925 1.10255 1.9259 1.10216 2.13411 1.30939L6.85897 6.0121L7.00006 6.15253L7.14115 6.0121L11.866 1.30939C12.0742 1.10216 12.4108 1.10255 12.6186 1.31028L13.3106 2.00237C13.5187 2.21045 13.5187 2.54779 13.3107 2.75584L13.3106 2.75585L7.37682 8.68973C7.37681 8.68974 7.37681 8.68974 7.37681 8.68974C7.16873 8.8978 6.83137 8.89779 6.6233 8.68973L0.689435 2.75584C0.48135 2.54776 0.481361 2.21042 0.689427 2.00238Z" fill="#F0830E" stroke="white" strokeWidth="0.4" />
                </svg>
            </button>
        </div>
    );
};

export default MyCalendarNavDesktop;

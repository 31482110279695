// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ColorInput_inputContainer__AqD3B {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 310px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 8px;
    padding: 8.5px 14px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;

}
/* .inputContainer {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 4px;
    width: fit-content;
  } */
  
  .ColorInput_textInput__EHTR2 {
    border: none;
    outline: none;
/*     padding: 8.5px 14px; */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    width: 80%;
  }
  
  .ColorInput_colorInput__ZRIb1 {
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 32px;
    height: 150%;
    padding: 2px;
    cursor: pointer;
  }
  
  .ColorInput_inputContainer__AqD3B:focus-within {
    border-color: #888; /* Цвет рамки при фокусе */
  }
  `, "",{"version":3,"sources":["webpack://./src/UI/ColorInput/ColorInput.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;IACZ,YAAY;IACZ,qCAAqC;IACrC,kBAAkB;IAClB,mBAAmB;IACnB,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;;AAErB;AACA;;;;;;;KAOK;;EAEH;IACE,YAAY;IACZ,aAAa;AACjB,6BAA6B;IACzB,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,UAAU;EACZ;;EAEA;IACE,sBAAsB;IACtB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,kBAAkB,EAAE,0BAA0B;EAChD","sourcesContent":[".inputContainer {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    width: 310px;\n    height: 40px;\n    border: 1px solid rgba(0, 0, 0, 0.38);\n    border-radius: 8px;\n    padding: 8.5px 14px;\n    font-family: 'Roboto';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 23px;\n\n}\n/* .inputContainer {\n    display: flex;\n    align-items: center;\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    padding: 4px;\n    width: fit-content;\n  } */\n  \n  .textInput {\n    border: none;\n    outline: none;\n/*     padding: 8.5px 14px; */\n    font-family: 'Roboto';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 23px;\n    width: 80%;\n  }\n  \n  .colorInput {\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    width: 32px;\n    height: 150%;\n    padding: 2px;\n    cursor: pointer;\n  }\n  \n  .inputContainer:focus-within {\n    border-color: #888; /* Цвет рамки при фокусе */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `ColorInput_inputContainer__AqD3B`,
	"textInput": `ColorInput_textInput__EHTR2`,
	"colorInput": `ColorInput_colorInput__ZRIb1`
};
export default ___CSS_LOADER_EXPORT___;

import create from 'zustand';

export interface AxiosError {
    status: number,
    message: string,
    response: {
        data: {
            error: string,
            message: string,
            status:number
        }
    }
}
interface ErrorStore {
    errorCode: number;
    errorStatus: boolean; 
    errorMessage: string; 
    setError: (error:AxiosError) => void; 
    clearError: () => void; 

}

const useErrorStore = create<ErrorStore>((set) => ({
    errorCode: 0,
    errorStatus: false,
    errorMessage: '',
    setError: (error:AxiosError) => {
        console.log(error)

        set({ errorMessage: error.response.data.message, errorCode: error.status, errorStatus: true  })
    },
    clearError: () =>{
        set({ errorStatus: false, errorMessage: '', errorCode: 0 })
    }
}));

export default useErrorStore;

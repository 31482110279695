// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderButton_button__dg-P\\+ {
    background: #262626;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HeaderButton_delete__7Dtgw {
    background: #fff;
    border: 2px solid #FF0B0B;
}

@media (max-width:1550px) {
    .HeaderButton_button__dg-P\\+ {
        height: 35px;
        width: 35px;
    }
    .HeaderButton_button__dg-P\\+ img{
            width: 25px;
            height: 25px;
    }
}`, "",{"version":3,"sources":["webpack://./src/UI/HeaderButton/HeaderButton.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI;QACI,YAAY;QACZ,WAAW;IACf;IACA;YACQ,WAAW;YACX,YAAY;IACpB;AACJ","sourcesContent":[".button {\n    background: #262626;\n    width: 40px;\n    height: 40px;\n    border-radius: 10px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.delete {\n    background: #fff;\n    border: 2px solid #FF0B0B;\n}\n\n@media (max-width:1550px) {\n    .button {\n        height: 35px;\n        width: 35px;\n    }\n    .button img{\n            width: 25px;\n            height: 25px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `HeaderButton_button__dg-P+`,
	"delete": `HeaderButton_delete__7Dtgw`
};
export default ___CSS_LOADER_EXPORT___;

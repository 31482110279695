import React from 'react'
import PageLayout from '../../layouts/PageLayout/PageLayout'
import SearchMenu from './components/SearchMenu/SearchMenu'
import styles from './GroupManager.module.css'
import GroupsList from './components/GroupsList/GroupsList'
const GroupManager = () => {
  return (
    <PageLayout title='Gruppenverwaltung' centerTitle>
      <div className={styles.main}>
        <SearchMenu />
        <GroupsList/>
      </div>
    </PageLayout>
  )
}

export default GroupManager

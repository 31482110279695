import React, { useEffect, useState } from 'react'
import styles from './SearchMenu.module.css'
import MyInput from '../../../../UI/MyInput/MyInput'
import useGroupStore from '../../store/groups'
import ColorInput from '../../../../UI/ColorInput/ColorInput'
import MyButton from '../../../../UI/MyButton/MyButton'
const SearchMenu = () => {
    const groupName = useGroupStore(store => store.groupName)
    const setGroupName = useGroupStore(store => store.setGroupName)
    const color = useGroupStore(store => store.color)
    const setColor = useGroupStore(store => store.setColor)
    const groupUrl = useGroupStore(store => store.groupUrl)
    const setGroupUrl = useGroupStore(store => store.setGroupUrl)
    const createGroup = useGroupStore(store => store.createGroup)
    const editMode = useGroupStore(store => store.editMode)
    const editGroup = useGroupStore(store => store.editGroup)
    const setEditMode = useGroupStore(store => store.setEditMode)
    const clearFields = useGroupStore(store => store.clearFields)




    const [groupNameError, setGroupNameError] = useState<boolean>(false);
    const [groupUrlError, setGroupUrlError] = useState<boolean>(false);
    const [colorError, setColorError] = useState<boolean>(false);

    const handleCreate = async () => {
        setEditMode(false)
        const isGroupNameEmpty = groupName.trim() === '';
        const isGroupUrlEmpty = groupUrl.trim() === '';
        const isColorEmpty = color.trim() === '';

        setGroupNameError(isGroupNameEmpty);
        setGroupUrlError(isGroupUrlEmpty);
        setColorError(isColorEmpty);

        // Проверка сразу после установки состояния
        if (isGroupNameEmpty || isGroupUrlEmpty || isColorEmpty) return;

        // Если ошибок нет, продолжайте выполнение
        await createGroup();
    };

    const handleEdit = async () => {
        await editGroup()
        await setEditMode(false)
        await clearFields()
    }
    return (
        <div className={styles.main}>
            <MyInput setValue={setGroupName} value={groupName} placeholder='Gruppenname' type='text' hasError={groupNameError} className={styles.input}/>
            <MyInput setValue={setGroupUrl} value={groupUrl} placeholder='Gruppenlink' type='text' hasError={groupUrlError} className={styles.input}/>
            <ColorInput setValue={setColor} value={color} placeholder='Farbe' hasError={colorError} className={styles.input}/>
            <div className={styles.buttonRow}>
                {editMode && <MyButton className={styles.button} onClick={handleEdit}>Bearbeiten</MyButton>}
                <MyButton className={styles.button} onClick={handleCreate}>Erstellen</MyButton>
            </div>
        </div>
    )
}

export default SearchMenu

import React, { FC, ReactNode } from 'react'
import styles from './AppLayout.module.css';
import logo from '../../assets/images/logo.png'
import navCalendarIcon from '../../assets/images/nav-calendar.svg'
import navListIcon from '../../assets/images/nav-list.svg'
import NavBar from '../NavBar/NavBar';

interface Props {
  children: ReactNode
}
const AppLayout: FC<Props> = ({ children }) => {
  return (
    <div className={styles.main}>
      <NavBar/>
      {children}

    </div>
  )
}

export default AppLayout

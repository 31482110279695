import React from 'react'
import PageLayout from '../../layouts/PageLayout/PageLayout'
import PlayerModule from '../../modules/PlayerModule/PlayerModule'

const AddPlayer = () => {
  return (
    <PlayerModule action='create'/>
  )
}

export default AddPlayer

import React, { FC, useState, useEffect, useRef } from "react";
import styles from "./SearchPlayerDropdown.module.css"; // Подключите свой CSS-модуль
import UsersService from "../../../../api/PlayerService";
import MyButton from "../../../../UI/MyButton/MyButton";


interface SearchPlayerDropdownProps {
  onSelectPlayer: (playerId: number) => void;
  role: 'player' | 'admin';
  className:string;
}

const SearchPlayerDropdown: FC<SearchPlayerDropdownProps> = ({ onSelectPlayer,role,className }) => {
  const [searchQuery, setSearchQuery] = useState<string>(""); // Поле ввода для поиска
  const [players, setPlayers] = useState<{ id: number; username: string }[]>([]); // Найденные игроки
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Выполняем поиск пользователей
  const handleSearch = async () => {
    try {
      /*   if (!searchQuery.trim()) return; // Если пустой запрос, не ищем */
      const { data } = await UsersService.searchUsers(searchQuery, role); // Замените на ваш API
      setPlayers(data);
      setIsDropdownOpen(true);
    } catch (error) {
      console.error("Ошибка поиска игроков:", error);
    }
  };

  // Закрытие выпадающего списка при клике вне компонента
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Выбор игрока
  const handleSelectPlayer = (playerId: number) => {
    onSelectPlayer(playerId);
    setIsDropdownOpen(false);
    setSearchQuery(""); // Очищаем поле ввода
  };

  return (
    <div className={`${styles.container} ${className}`} ref={dropdownRef}>
      <div className={`${styles.inputContainer} ${className}`}>
        <input
          type="text"
          className={styles.input}
          placeholder="Поиск игрока..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}

        />
        <MyButton onClick={handleSearch} className={styles.searchButton}>
          Suchen
        </MyButton>
      </div>
      {isDropdownOpen && players.length > 0 && (
        <div className={styles.dropdown}>
          {players.map((player) => (
            <div
              key={player.id}
              className={styles.dropdownItem}
              onClick={() => handleSelectPlayer(player.id)}
            >
              {player.username}
            </div>
          ))}
        </div>
      )}
      {isDropdownOpen && players.length === 0 && (
        <div className={styles.noResults}>Игроки не найдены</div>
      )}
    </div>
  );
};

export default SearchPlayerDropdown;

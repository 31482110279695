import React, { FC } from 'react'
import { IUser } from '../../../../models/IUser';
import styles from './UsersList.module.css';
import { Link } from 'react-router-dom';
import MySelect from '../../../../UI/MySelect/MySelect';
import { useAuthStore } from '../../../../app/store/auth';
import { playerTypes } from '../../../../constants/playerTypes';

interface UsersListPorps {
  users: IUser[];
  changeUserState: (userId: number) => void
}

const UsersList: FC<UsersListPorps> = ({ users, changeUserState }) => {
  const role = useAuthStore(store => store.role);


  
  return (
    <div className={styles.main}>
      <div className={styles.titles}>
        <div className={styles.title}>Spielername</div>
        <div className={styles.title}>Spielerstatus</div>
        <div className={styles.title}>Telefonnummer</div>
      </div>
      <div className={styles.usersRow}>
        {users.map((user, index) =>
          <Link to={`/player/${user.id}`} className={styles.userItem} key={index}>
            <div className={styles.userValue}>{user.username}</div>
            {role == 'admin' && user.role !== 'admin' ?
              <MySelect
                className={styles.userValue}
                options={playerTypes} onChange={() => changeUserState(user.id)} value={user.role} />
              :
              <div className={styles.userValue}>{user.role !== 'admin' ? playerTypes.find(type => type.value == user.role)?.label: 'Admin' } </div>

            }
            <div className={styles.userValue}>{user.phone ? user.phone : "---"}</div>
          </Link>
        )}

      </div>
    </div>
  )
}

export default UsersList

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchPlayerDropdown_container__mVjF\\+ {
    position: relative;
    width: 100%;
}

.SearchPlayerDropdown_inputContainer__PIuGC {
    display: flex;
    gap: 8px;
    align-items: center;
}

.SearchPlayerDropdown_input__Iesav {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;

    border-radius: 8px;



    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;




}

.SearchPlayerDropdown_searchButton__zE05m {
    width: 130px;
    padding: 8px 12px;
}

.SearchPlayerDropdown_dropdown__L0USC {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 10;
}

.SearchPlayerDropdown_dropdownItem__1qtjC {
    padding: 8px;
    cursor: pointer;
    transition: background 0.3s;
}

.SearchPlayerDropdown_dropdownItem__1qtjC:hover {
    background: #f0f0f0;
}

.SearchPlayerDropdown_noResults__Oix5p {
    padding: 8px;
    color: #999;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/modules/TrainigManager/components/searchPlayerDrowDown/SearchPlayerDropdown.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;;IAEf,kBAAkB;;;;IAIlB,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;;;;;AAKrB;;AAEA;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,QAAQ;IACR,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".container {\n    position: relative;\n    width: 100%;\n}\n\n.inputContainer {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n}\n\n.input {\n    width: 100%;\n    padding: 8px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    font-size: 14px;\n\n    border-radius: 8px;\n\n\n\n    font-family: 'Roboto';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 23px;\n\n\n\n\n}\n\n.searchButton {\n    width: 130px;\n    padding: 8px 12px;\n}\n\n.dropdown {\n    position: absolute;\n    top: 100%;\n    left: 0;\n    right: 0;\n    background: white;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    max-height: 200px;\n    overflow-y: auto;\n    z-index: 10;\n}\n\n.dropdownItem {\n    padding: 8px;\n    cursor: pointer;\n    transition: background 0.3s;\n}\n\n.dropdownItem:hover {\n    background: #f0f0f0;\n}\n\n.noResults {\n    padding: 8px;\n    color: #999;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SearchPlayerDropdown_container__mVjF+`,
	"inputContainer": `SearchPlayerDropdown_inputContainer__PIuGC`,
	"input": `SearchPlayerDropdown_input__Iesav`,
	"searchButton": `SearchPlayerDropdown_searchButton__zE05m`,
	"dropdown": `SearchPlayerDropdown_dropdown__L0USC`,
	"dropdownItem": `SearchPlayerDropdown_dropdownItem__1qtjC`,
	"noResults": `SearchPlayerDropdown_noResults__Oix5p`
};
export default ___CSS_LOADER_EXPORT___;

import React, { FC } from 'react';
import styles from './HeaderButton.module.css'
interface HeaderButtonProps{
    icon: string;
    onClick: ()=> void;
    className?: string
}
const HeaderButton:FC<HeaderButtonProps> = ({icon, onClick, className}) => {
  return (
    <button onClick={onClick} className={`${styles.button} ${className == "delete" ? styles.delete : className}`}>
        <img src={icon} alt="header icon" />
    </button>
  )
}

export default HeaderButton

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_loader__cNLdr {
    width: 100%;
    max-height: 100%;
    max-height: 100%;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Loader_loaderSmal__Lmpf5{
    height: 150px;
    max-height: 150px;
    min-height: 150px;
  }
  .Loader_loaderSmal__Lmpf5 .Loader_LoaderIcon__n-qeb{
    width: 50px;
    height: 50px;
  }
  .Loader_LoaderIcon__n-qeb {
    width: 250px;
    height: 250px;
    border-radius: 50%; /* Делает круглым */
    animation: Loader_rotate__eegsn 5s infinite linear;
  }
  
  @keyframes Loader_rotate__eegsn {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  

  @media (max-width:600px) {


    .Loader_LoaderIcon__n-qeb {
      width: 100px;
      height: 100px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/UI/Loader/Loader.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,aAAa;IACb,iBAAiB;IACjB,iBAAiB;EACnB;EACA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,YAAY;IACZ,aAAa;IACb,kBAAkB,EAAE,mBAAmB;IACvC,kDAAoC;EACtC;;EAEA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF;;;EAGA;;;IAGE;MACE,YAAY;MACZ,aAAa;IACf;EACF","sourcesContent":[".loader {\n    width: 100%;\n    max-height: 100%;\n    max-height: 100%;\n    margin: auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  .loaderSmal{\n    height: 150px;\n    max-height: 150px;\n    min-height: 150px;\n  }\n  .loaderSmal .LoaderIcon{\n    width: 50px;\n    height: 50px;\n  }\n  .LoaderIcon {\n    width: 250px;\n    height: 250px;\n    border-radius: 50%; /* Делает круглым */\n    animation: rotate 5s infinite linear;\n  }\n  \n  @keyframes rotate {\n    from {\n      transform: rotate(0deg);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }\n  \n\n  @media (max-width:600px) {\n\n\n    .LoaderIcon {\n      width: 100px;\n      height: 100px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": `Loader_loader__cNLdr`,
	"loaderSmal": `Loader_loaderSmal__Lmpf5`,
	"LoaderIcon": `Loader_LoaderIcon__n-qeb`,
	"rotate": `Loader_rotate__eegsn`
};
export default ___CSS_LOADER_EXPORT___;

import { create } from "zustand";
import { useWindowWidth } from "../../../hooks/useWindowWidth";

export type CalendarPage = 'Trainings' | 'Anmeldungen';
interface CalendarState {
    calendarPage: CalendarPage;
    setCalendarPage: (calendarPage: CalendarPage) => void;
    currentDate: Date;
    setCurrentDate: (currentDate: Date) => void
    /* Mobile */
    isMobileVersion: boolean;
    setIsMobileVersion: (width: number) => void;
    isCalendarMobile: boolean,
    setIsCalendarMobile: (isCalendarMobile: boolean) => void
}

export const useCalendarStore = create<CalendarState>((set) => ({
    calendarPage: (['Trainings', 'Anmeldungen'].includes(localStorage.getItem('calendarPage') || '') 
    ? (localStorage.getItem('calendarPage') as CalendarPage)
    : 'Trainings'),


    currentDate: (() => {
        const storedDate = localStorage.getItem('calendarDate');
        return storedDate ? new Date(storedDate) : new Date();
    })(),
    
    setCurrentDate: (currentDate: Date) => {
        localStorage.setItem('calendarDate'  ,String(currentDate))
        set({ currentDate })
    },
    setCalendarPage: (calendarPage: CalendarPage) => {
        localStorage.setItem('calendarPage'  ,calendarPage)
        set({ calendarPage })
    },
    isMobileVersion: false,
    setIsMobileVersion: (width: number) => {
        if (width >= 790) {
            set({ isMobileVersion: false })
        } else {
            set({ isMobileVersion: true })
        }
    },
    isCalendarMobile: false,
    setIsCalendarMobile: (isCalendarMobile: boolean) => set({ isCalendarMobile })


}));

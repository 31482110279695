// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChangePassword_input__mbgva{
    width: 100%;
}

.ChangePassword_button__361UJ{
    width: 100%;
}


.ChangePassword_errorText__fco1d {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    color: #FB2424;
    line-height: 1.4;
}`, "",{"version":3,"sources":["webpack://./src/modules/PlayerModule/components/ChangePassword/ChangePassword.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;;AAGA;IACI,eAAe;IACf,iCAAiC;IACjC,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".input{\n    width: 100%;\n}\n\n.button{\n    width: 100%;\n}\n\n\n.errorText {\n    font-size: 16px;\n    font-family: 'Roboto', sans-serif;\n    color: #FB2424;\n    line-height: 1.4;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `ChangePassword_input__mbgva`,
	"button": `ChangePassword_button__361UJ`,
	"errorText": `ChangePassword_errorText__fco1d`
};
export default ___CSS_LOADER_EXPORT___;

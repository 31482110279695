// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.NavDropDownBtn_navButton__TXm3c {

    width: 55px;
    height: 55px;
    background: inherit;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    cursor: pointer;
}

.NavDropDownBtn_navIcon__ZQLzp {

    width: 31px;
    height: 31px;
}

.NavDropDownBtn_navButton__TXm3c.NavDropDownBtn_active__MiTbN {
    background: #262626;
    border-radius: 10px;
}

.NavDropDownBtn_dropDown__Sc7Z8 {
    position: absolute;
    top: 0; /* Чтобы меню было ниже кнопки */
    left: 100%; /* Чтобы меню отображалось справа от кнопки */

    display: none;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1000;
}

.NavDropDownBtn_show__VtwAV {
    display: block;
}

.NavDropDownBtn_dropDownItem__Jf\\+iP {
    display: flex;
    padding: 10px 15px;
    cursor: pointer;
    white-space: nowrap;


font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 23px;

color: #000000;


}

.NavDropDownBtn_dropDownItem__Jf\\+iP:hover {
    background-color: #f0f0f0;
}
`, "",{"version":3,"sources":["webpack://./src/UI/NavDropDownBtn/NavDropDownBtn.module.css"],"names":[],"mappings":";AACA;;IAEI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;;IAElB,eAAe;AACnB;;AAEA;;IAEI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,MAAM,EAAE,gCAAgC;IACxC,UAAU,EAAE,6CAA6C;;IAEzD,aAAa;IACb,uBAAuB;IACvB,uCAAuC;IACvC,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,mBAAmB;;;AAGvB,qBAAqB;AACrB,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;;AAEjB,cAAc;;;AAGd;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["\n.navButton {\n\n    width: 55px;\n    height: 55px;\n    background: inherit;\n    border: none;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n\n    cursor: pointer;\n}\n\n.navIcon {\n\n    width: 31px;\n    height: 31px;\n}\n\n.navButton.active {\n    background: #262626;\n    border-radius: 10px;\n}\n\n.dropDown {\n    position: absolute;\n    top: 0; /* Чтобы меню было ниже кнопки */\n    left: 100%; /* Чтобы меню отображалось справа от кнопки */\n\n    display: none;\n    background-color: white;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    border-radius: 4px;\n    z-index: 1000;\n}\n\n.show {\n    display: block;\n}\n\n.dropDownItem {\n    display: flex;\n    padding: 10px 15px;\n    cursor: pointer;\n    white-space: nowrap;\n\n\nfont-family: 'Roboto';\nfont-style: normal;\nfont-weight: 400;\nfont-size: 16px;\nline-height: 23px;\n\ncolor: #000000;\n\n\n}\n\n.dropDownItem:hover {\n    background-color: #f0f0f0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navButton": `NavDropDownBtn_navButton__TXm3c`,
	"navIcon": `NavDropDownBtn_navIcon__ZQLzp`,
	"active": `NavDropDownBtn_active__MiTbN`,
	"dropDown": `NavDropDownBtn_dropDown__Sc7Z8`,
	"show": `NavDropDownBtn_show__VtwAV`,
	"dropDownItem": `NavDropDownBtn_dropDownItem__Jf+iP`
};
export default ___CSS_LOADER_EXPORT___;

import { MyEvent } from "./CustomEvent/CustomEvent";


const eventPropGetter = (event: MyEvent) => {
    const backgroundColor = event.color;
    return {
      style: {
        backgroundColor,
        color: 'white', // Цвет текста
        borderRadius: '4px',
        padding: '0px',
      },
    };
  };
export default eventPropGetter
import React, { useEffect, useState } from 'react'
import PageLayout from '../../layouts/PageLayout/PageLayout'
import styles from './Players.module.css'
import MyInput from '../../UI/MyInput/MyInput';
import MyButton from '../../UI/MyButton/MyButton';
import { IUser } from '../../models/IUser';
import UsersService from './api/UsersService';
import UsersList from './components/UsersList/UsersList';
import HeaderButton from '../../UI/HeaderButton/HeaderButton';
import addUser from '../../assets/images/add-training.svg'
import useApiRequest from '../../hooks/UseApiRequestState';
import Loader from '../../UI/Loader/Loader';
import { useAuthStore } from '../../app/store/auth';
import { RouteNames } from '../../app/router';
import { useNavigate } from 'react-router-dom';
import MySelect from '../../UI/MySelect/MySelect';
import { SelectOption } from '../../models/SelectOption';
import { useWindowWidth } from '../../hooks/useWindowWidth';


const Players = () => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [users, setUsers] = useState<IUser[]>([])

    const { data: usersData, error, isLoading, execute } = useApiRequest(UsersService.searchUsers);
    const role = useAuthStore(store => store.role);
    const [userType, setUserType] = useState<string>('player')
    const navigate = useNavigate();
    const handleSearchUsers = async () => {
        localStorage.setItem('plaersSearchQuery', searchQuery)
        localStorage.setItem('plaersUserType', userType)
        await execute(searchQuery, userType)
    };

    const width = useWindowWidth();

    const usersTypes: SelectOption[] = [
        {
            value: 'player', label: 'Spieler'
        },
        {
            value: 'admin', label: width>= 700 ? 'Administration' : 'Admin'
        }
    ]
    


    useEffect(() => {
        const localSearchquery = localStorage.getItem('plaersSearchQuery') || ''
        const localUserType = localStorage.getItem('plaersUserType') || 'player'

        setSearchQuery(localSearchquery)
        setUserType(localUserType)
        execute(localSearchquery, localUserType)
    }, []);

    useEffect(() => {
        if (usersData)
            setUsers(usersData.data)
    }, [usersData])

    const changeUserState = async (userId: number) => {

        try {
            const { data, status } = await UsersService.convertPlayerState(userId);

            console.log(data, status)
            const newUser: IUser[] = users.map(user => {
                if (user.id == data.id) {
                    return {
                        ...user, role: data.role
                    }
                } else return user;
            })
            setUsers(newUser)
        } catch (e) {
            console.log(e)
        }




    }


    return (
        <PageLayout
            title={width >= 770 ? 'Spieler' : ''}
            headereComopnent={
                <div className={styles.header}>
                    <MyInput type='text' placeholder='Player info' value={searchQuery} setValue={setSearchQuery}
                        className={styles.headerInput}
                        onEnter={handleSearchUsers}
                    />
                    <MySelect options={usersTypes} onChange={setUserType} value={userType} className={styles.headerSelect} />
                    <MyButton onClick={handleSearchUsers}
                        className={styles.headerButton}
                    >
                        Suchen
                    </MyButton>
                    {role == 'admin' && width >=480 && <HeaderButton icon={addUser} onClick={() => navigate(RouteNames.ADD_PLAYER)} />}
                </div>
            }
        >

            {isLoading ?
                <Loader />
                :
                <>
                <UsersList users={users} changeUserState={changeUserState} />
                {role == 'admin' && width <=480 && <HeaderButton icon={addUser} onClick={() => navigate(RouteNames.ADD_PLAYER)} className={styles.mobileNutton} />}
                </>
                
               
            }

        </PageLayout>
    )
}

export default Players

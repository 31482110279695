import React, { FC } from 'react';
import { Calendar, DateLocalizer, Formats, NavigateAction, View } from 'react-big-calendar';
import CustomEvent, { MyEvent } from '../CustomEvent/CustomEvent';
import eventPropGetter from '../../components/eventPropGetter';
import 'moment/locale/de'; // Импорт немецкой локализации, если используется moment

interface DesktopCalendarProps {
    filteredEvents: MyEvent[];
    localizer: DateLocalizer; // Предполагаем, что он поддерживает локализацию
    view: View;
    currentDate: Date;
    setCurrentDate: (date: Date) => void;
    onEventClick: (calendarEvent: MyEvent) => void;
}

// Форматы с учетом немецкого языка
const formats: Formats = {
    timeGutterFormat: 'HH:mm',
    eventTimeRangeFormat: ({ start, end }, _, localizer) =>
        //@ts-ignore
        `${localizer.format(start, 'HH:mm')} - ${localizer.format(end, 'HH:mm')}`,
    agendaTimeFormat: 'HH:mm',
    dayHeaderFormat: 'dddd, DD.MM.YYYY', // Длинный формат дня недели
    dayRangeHeaderFormat: ({ start, end }, _, localizer) =>
        //@ts-ignore
        `${localizer.format(start, 'DD.MM.YYYY')} - ${localizer.format(end, 'DD.MM.YYYY')}`,
    monthHeaderFormat: 'MMMM YYYY', // Немецкий формат месяца
};

const DesktopCalendar: FC<DesktopCalendarProps> = ({
    localizer,
    filteredEvents,
    view,
    currentDate,
    setCurrentDate,
    onEventClick,
}) => {
    const dayPropGetter = (date: Date) => {
        return {
            className: 'rbc-day-slot',
        };
    };

    const handleNavigate = (newDate: Date, view: View, action: NavigateAction) => {
        // Блокируем изменение даты на вид DATE
        if (action === 'DATE') {
            return;
        }
        setCurrentDate(newDate);
    };

    return (
        <div style={{ height: '100vh' }}>
            <Calendar<MyEvent>
                localizer={localizer} // Немецкий локализатор
                events={filteredEvents} // Отфильтрованные события
                startAccessor="start"
                endAccessor="end"
                style={{ height: '90%' }}
                components={{
                    toolbar: () => null,
                    event: CustomEvent,
                }}
                dayPropGetter={dayPropGetter}
                view={view}
                date={currentDate}
                onNavigate={handleNavigate}
                eventPropGetter={eventPropGetter}
                onSelectEvent={(calendarEvent) => onEventClick(calendarEvent)}
                formats={formats} // Форматы с учетом локализации
            />
        </div>
    );
};

export default DesktopCalendar;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Players_header__55qpa{
    display: flex;
    flex-direction: row;
    column-gap: 30px;
}

.Players_headerButton__QRE1T{
    width: 150px;
}



@media(max-width:1200px){
    .Players_headerInput__wJBRT,.Players_headerSelect__SKYJ-{
        width: 230px;
        height: 40px;
    }
    .Players_headerButton__QRE1T{
        width: 100px;
    }
}


@media(max-width: 1550px){
    .Players_headerInput__wJBRT,.Players_headerSelect__SKYJ-,.Players_headerButton__QRE1T{
        max-height: 35px !important;
        min-height: 35px !important;

    }
   
}
@media(max-width:880px){
    .Players_headerInput__wJBRT,.Players_headerSelect__SKYJ-{
        width: 140px;
    }
    .Players_headerButton__QRE1T{
        width: 80px;
    }
}


@media(max-width:565px){
    .Players_headerInput__wJBRT,.Players_headerSelect__SKYJ-{
        width: 110px;
    }
    .Players_headerButton__QRE1T{
        width: 60px;
        font-size: 15px;
    }
    .Players_header__55qpa{
        column-gap: 0;
        justify-content: space-between;
        width: 100%;
    }
}


.Players_mobileNutton__vtu8f{
    position: fixed;
    bottom: 10px;
    right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Players/Players.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB;;;;AAIA;IACI;QACI,YAAY;QACZ,YAAY;IAChB;IACA;QACI,YAAY;IAChB;AACJ;;;AAGA;IACI;QACI,2BAA2B;QAC3B,2BAA2B;;IAE/B;;AAEJ;AACA;IACI;QACI,YAAY;IAChB;IACA;QACI,WAAW;IACf;AACJ;;;AAGA;IACI;QACI,YAAY;IAChB;IACA;QACI,WAAW;QACX,eAAe;IACnB;IACA;QACI,aAAa;QACb,8BAA8B;QAC9B,WAAW;IACf;AACJ;;;AAGA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;AACf","sourcesContent":[".header{\n    display: flex;\n    flex-direction: row;\n    column-gap: 30px;\n}\n\n.headerButton{\n    width: 150px;\n}\n\n\n\n@media(max-width:1200px){\n    .headerInput,.headerSelect{\n        width: 230px;\n        height: 40px;\n    }\n    .headerButton{\n        width: 100px;\n    }\n}\n\n\n@media(max-width: 1550px){\n    .headerInput,.headerSelect,.headerButton{\n        max-height: 35px !important;\n        min-height: 35px !important;\n\n    }\n   \n}\n@media(max-width:880px){\n    .headerInput,.headerSelect{\n        width: 140px;\n    }\n    .headerButton{\n        width: 80px;\n    }\n}\n\n\n@media(max-width:565px){\n    .headerInput,.headerSelect{\n        width: 110px;\n    }\n    .headerButton{\n        width: 60px;\n        font-size: 15px;\n    }\n    .header{\n        column-gap: 0;\n        justify-content: space-between;\n        width: 100%;\n    }\n}\n\n\n.mobileNutton{\n    position: fixed;\n    bottom: 10px;\n    right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Players_header__55qpa`,
	"headerButton": `Players_headerButton__QRE1T`,
	"headerInput": `Players_headerInput__wJBRT`,
	"headerSelect": `Players_headerSelect__SKYJ-`,
	"mobileNutton": `Players_mobileNutton__vtu8f`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Errors_text__PkgDY {

    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #000000;



}
.Errors_main__Hpvw0{
    z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./src/components/Errors/Errors.module.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;;;;AAIlB;AACA;IACI,aAAa;AACjB","sourcesContent":[".text {\n\n    text-align: center;\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 27px;\n    color: #000000;\n\n\n\n}\n.main{\n    z-index: 1000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `Errors_text__PkgDY`,
	"main": `Errors_main__Hpvw0`
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Calendar_selectPage__JBdEA {
    width: 150px;
    margin-bottom: 0 !important;
}

.Calendar_headerButtonRow__wQHd3 {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    align-items: center;

}

@media (max-width: 950px) {
  /*   .selectView {
        display: none;
    } */
    .Calendar_selectPage__JBdEA {
        width: 120px;
    }
}

@media (max-width: 700px) {
    .Calendar_headerButtonRow__wQHd3 {
        display: contents;
    }

}


@media(max-width: 580px){
    .Calendar_selectPage__JBdEA {
        width: 120px;
    }
    
}

.Calendar_AddMobileIcon__luizk{
    position: absolute;
    right: 10px; 
    bottom: 10px;
    width: 45px;
    height: 45px;
}

.Calendar_AddMobileIcon__luizk img{
    width: 30px;
    height: 30px;
}

@media(min-width:580px){
    .Calendar_main__iEbtc{
        max-width: calc(100vw - 75px);
    }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Calendar/Calendar.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,mBAAmB;;AAEvB;;AAEA;EACE;;OAEK;IACH;QACI,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,iBAAiB;IACrB;;AAEJ;;;AAGA;IACI;QACI,YAAY;IAChB;;AAEJ;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI;QACI,6BAA6B;IACjC;AACJ","sourcesContent":[".selectPage {\n    width: 150px;\n    margin-bottom: 0 !important;\n}\n\n.headerButtonRow {\n    display: flex;\n    flex-direction: row;\n    column-gap: 10px;\n    align-items: center;\n\n}\n\n@media (max-width: 950px) {\n  /*   .selectView {\n        display: none;\n    } */\n    .selectPage {\n        width: 120px;\n    }\n}\n\n@media (max-width: 700px) {\n    .headerButtonRow {\n        display: contents;\n    }\n\n}\n\n\n@media(max-width: 580px){\n    .selectPage {\n        width: 120px;\n    }\n    \n}\n\n.AddMobileIcon{\n    position: absolute;\n    right: 10px; \n    bottom: 10px;\n    width: 45px;\n    height: 45px;\n}\n\n.AddMobileIcon img{\n    width: 30px;\n    height: 30px;\n}\n\n@media(min-width:580px){\n    .main{\n        max-width: calc(100vw - 75px);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectPage": `Calendar_selectPage__JBdEA`,
	"headerButtonRow": `Calendar_headerButtonRow__wQHd3`,
	"AddMobileIcon": `Calendar_AddMobileIcon__luizk`,
	"main": `Calendar_main__iEbtc`
};
export default ___CSS_LOADER_EXPORT___;

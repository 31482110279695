// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlayerModule_main__hu5dJ {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 20px;
    flex-wrap: wrap;
    row-gap: 30px;
    height: 100%;
}

.PlayerModule_row__3KyDC {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.PlayerModule_inputRow__Xu\\+SR {
    display: flex;
    flex-direction: column;
    row-gap: 7px;
}

.PlayerModule_inputTitle__2GAfU {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 27px;
    color: #000000;
}

.PlayerModule_popUpText__3k609{
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: #000000;
    text-align: center;
}

.PlayerModule_input__nAs6e {
    width: 350px;
}

.PlayerModule_textarea__iu\\+5I {
    padding: 16px 14px;
    border: 1px solid rgba(0, 0, 0, 0.38);
    border-radius: 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: rgba(0, 0, 0, 1);
}

.PlayerModule_headerRow__OUmf0{
    display: flex;
    flex-direction:  row;
    column-gap: 30px;
}


@media (max-width: 450px) {
    .PlayerModule_input__nAs6e,
    .PlayerModule_row__3KyDC,
    .PlayerModule_inputRow__Xu\\+SR {
        width: 100% !important;
    }

    .PlayerModule_headerRow__OUmf0{
        column-gap: 10px;
    }
}



`, "",{"version":3,"sources":["webpack://./src/modules/PlayerModule/PlayerModule.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,qCAAqC;IACrC,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,gBAAgB;AACpB;;;AAGA;IACI;;;QAGI,sBAAsB;IAC1B;;IAEA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".main {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    padding: 15px 20px;\n    flex-wrap: wrap;\n    row-gap: 30px;\n    height: 100%;\n}\n\n.row {\n    display: flex;\n    flex-direction: column;\n    row-gap: 20px;\n}\n\n.inputRow {\n    display: flex;\n    flex-direction: column;\n    row-gap: 7px;\n}\n\n.inputTitle {\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 17px;\n    line-height: 27px;\n    color: #000000;\n}\n\n.popUpText{\n    font-family: 'Poppins';\n    font-weight: 400;\n    font-size: 17px;\n    line-height: 20px;\n    color: #000000;\n    text-align: center;\n}\n\n.input {\n    width: 350px;\n}\n\n.textarea {\n    padding: 16px 14px;\n    border: 1px solid rgba(0, 0, 0, 0.38);\n    border-radius: 8px;\n    font-family: 'Roboto';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 23px;\n    color: rgba(0, 0, 0, 1);\n}\n\n.headerRow{\n    display: flex;\n    flex-direction:  row;\n    column-gap: 30px;\n}\n\n\n@media (max-width: 450px) {\n    .input,\n    .row,\n    .inputRow {\n        width: 100% !important;\n    }\n\n    .headerRow{\n        column-gap: 10px;\n    }\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `PlayerModule_main__hu5dJ`,
	"row": `PlayerModule_row__3KyDC`,
	"inputRow": `PlayerModule_inputRow__Xu+SR`,
	"inputTitle": `PlayerModule_inputTitle__2GAfU`,
	"popUpText": `PlayerModule_popUpText__3k609`,
	"input": `PlayerModule_input__nAs6e`,
	"textarea": `PlayerModule_textarea__iu+5I`,
	"headerRow": `PlayerModule_headerRow__OUmf0`
};
export default ___CSS_LOADER_EXPORT___;

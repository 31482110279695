import { AxiosResponse } from "axios";
import $api from "../../../app/api/http";
import { ILocation } from "../../../models/location";
import { IGroup } from "../../../models/group";
export interface CreateGroupDto {
    groupName: string;
    groupUrl: string;
    color: string;
}
export interface ChangeGroupDto extends CreateGroupDto {
    id: number
}
export class GroupManagerService {
    static async getGroups(): Promise<AxiosResponse<IGroup[]>> {
        return $api.get('/groups')
    }

    static async createGroup(dto: CreateGroupDto): Promise<AxiosResponse<IGroup[]>> {
        return $api.post('/groups', dto)
    }

    static async deleteGroup(id: number): Promise<AxiosResponse<IGroup[]>> {
        return $api.delete('/groups/' + id)
    }

    static async changeVisible(id: number): Promise<AxiosResponse<IGroup[]>> {
        return $api.put('/groups/' + id + '/visible')
    }

    static async changeIsToAdult(id: number): Promise<AxiosResponse<IGroup[]>> {
        return $api.put('/groups/' + id + '/isToAdult')
    }

    static async changeOrder(id: number, order: number): Promise<AxiosResponse<IGroup[]>> {
        return $api.put('/groups/' + id + '/order', { order })
    }

    static async changeGroup(dto: ChangeGroupDto ): Promise<AxiosResponse<IGroup[]>> {
        return $api.put('/groups/' , dto)
    }
}
import { ILocation } from "../../../models/location";
import { SelectOption } from "../../../models/SelectOption";


export const formatLocations = (locations: ILocation[]):SelectOption[] => {
    return locations.map(location => {
        return {
            value: String(location.id),
            label: location.locationName
        }
    })
}
import { AxiosResponse } from "axios";
import $api from "../../../app/api/http";
import { IGroup } from "../../../models/group";
import { ILocation } from "../../../models/location";
import { ITraining } from "../../../models/ITraining";
import { networkInterfaces } from "os";

export interface ChangePrasentRes{
    id:number;
    isPresent: boolean
}
export interface EditTrainigDto{
    id: number, 
    startTime: string, 
    endTime: string,
    trainerId:number,
    adminComment: string
}

export interface CreateTrainigDto{
    startTime:string,
    endTime:string,
    repeat_type: number,
    groupId: string,
    locationId: string,
    adminComment: string,
    trainerId: number
}

export default class TrainingManagerService {
    static async getGroup(): Promise<AxiosResponse<IGroup[]>> {
        return $api.get('/groups?visible=true')
    }
    static async getLocations(): Promise<AxiosResponse<ILocation[]>> {
        return $api.get('/locations?visible=true')
    }

    static async postTraining(dto: CreateTrainigDto): Promise<AxiosResponse<ITraining>> {
        return $api.post('/trainings', dto)
    }
    static async getTrainingById(id: string): Promise<AxiosResponse<ITraining>> {
        return $api.get(`/trainings/${id}`)
    }

    static async editTraining(dto: EditTrainigDto): Promise<AxiosResponse<ITraining>> {
        return $api.put(`/trainings/${dto.id}`, dto)
    }
    static async editTrainingAll(dto: EditTrainigDto): Promise<AxiosResponse<ITraining>> {
        return $api.put('/trainings', {
            ...dto, trainingDatesId: dto.id

        })
    }
    static async deleteTraining(id: string, reason: string): Promise<AxiosResponse> {
        return $api.delete(`/trainings/${id}`, {
            data: {
                reason
            }
        })
    }

    static async deleteAllTrainings(trainingDatesId: string, reason: string): Promise<AxiosResponse<ITraining>> {
        return $api.delete('/trainings', {
            data: { trainingDatesId, reason }
        })
    }


    static async addPlayerToTraining(trainingDatesId: number, userId: number): Promise<AxiosResponse<ITraining>> {
        return $api.post('/applications/from-admin', {
            trainingDatesId, userId 
        })
    }
    static async addPlayerToAllTraining(trainingId: number, userId: number): Promise<AxiosResponse<ITraining>> {
        return $api.post(`/applications/from-admin-to-all?trainingId=${trainingId}&userId=${userId}`);

    }

    static async changePrasent(applicationId:number): Promise<AxiosResponse<ChangePrasentRes>> {
        return $api.put('/applications/isPresent/' + applicationId )
    }


    static async deletePlayerApplication(applicationId:number): Promise<AxiosResponse<ITraining>> {
        return $api.delete('/applications/delete-player-anmeldung/' + applicationId )
    }

    static async deleteAllPlayerApplication(trainingId: number, userId: number): Promise<AxiosResponse<ITraining>> {
        return $api.delete(`/applications/delete-all-player-anmeldungen?trainingId=${trainingId}&userId=${userId}` )
    }


}

import { create } from "zustand";
import { ChangeGroupDto, CreateGroupDto, GroupManagerService } from "../api/GroupManagerService";
import { IGroup } from "../../../models/group";
import { areAllFieldsFilled } from "../../../helpers/areAllFieldsFilled";


interface GroupsState {
    groupName: string;
    setGroupName: (groupName: string) => void;
    groupUrl: string;
    setGroupUrl: (groupLink: string) => void;
    color: string;
    setColor: (color: string) => void;
    editMode: boolean,
    setEditMode: (editMode: boolean) => void
    fetchGroups: () => void;
    groups: IGroup[],
    changeVisible: (id: number) => void;
    changeIsToAdult: (id: number) => void;
    createGroup: () => void;
    clearFields: () => void;
    deleteGroup: (id: number) => void;
    changeOrder:  (id:number, order:number) => void;
    editGroup: () => void,

    editId: number;
    setEditid: (editId: number)=> void
}

const useGroupStore = create<GroupsState>((set, get) => ({
    groupName: '',
    setGroupName: (groupName: string) => set({ groupName }),

    groupUrl: '',
    setGroupUrl: (groupUrl: string) => set({ groupUrl }),

    color: '#000',
    setColor: (color: string) => set({ color }),
    groups: [],
    editMode: false,
    setEditMode: (editMode: boolean) => set({editMode}),
    fetchGroups: async () => {
        try {
            const { data } = await GroupManagerService.getGroups()
            set({ groups: data })
            console.log(data)
        } catch (error) {
            console.error(error)
        }

    },
    changeVisible: async (id: number) => {
        //DOTO пределать бек под то, что бы просто менялся на противоположный 
        const { fetchGroups } = get();
        await GroupManagerService.changeVisible(id)
        await fetchGroups()
    },
    changeIsToAdult: async (id: number) => {
        //DOTO пределать бек под то, что бы просто менялся на противоположный 
        const { fetchGroups } = get();
        await GroupManagerService.changeIsToAdult(id)
        await fetchGroups()
    },
    createGroup: async () => {
        const { groupName, groupUrl, color, fetchGroups, clearFields } = get();
        const createDto: CreateGroupDto = {
            groupName, groupUrl, color
        }
        try {
            await GroupManagerService.createGroup(createDto)
            await fetchGroups();
            await clearFields()
        } catch (error) {
            console.error(error)
        }

    },
    clearFields: () => {
        set({ groupName: '', groupUrl: '', color: '#000' })
    },
    deleteGroup: async (id: number) => {
        const { fetchGroups } = get();
        try {
            await GroupManagerService.deleteGroup(id)
            await fetchGroups();
        } catch (error) {
            console.error(error)
        }
    },
    changeOrder: async (id:number, order:number) =>  {
        const { fetchGroups } = get();
        try {
            await GroupManagerService.changeOrder(id,order)
            await fetchGroups();
        } catch (error) {
            console.error(error)
        }
    },
    editGroup: async() => {
        const { groupName, groupUrl, color, fetchGroups, clearFields,editId } = get();
        const changeGroupDto: ChangeGroupDto = { 
            groupName, groupUrl, color, id: editId
        }

        try {
            await GroupManagerService.changeGroup(changeGroupDto)
            await fetchGroups();
        } catch (error) {
            console.error(error)
        }
    },

    editId: 0,
    setEditid: (editId: number) => set({editId})

}));

export default useGroupStore;

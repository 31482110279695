import { IGroup } from "../../../models/group";
import { SelectOption } from "../../../models/SelectOption";

export const formatGroups = (groups:IGroup[]):SelectOption[] => {
    return groups.map(group => {
        return {
            value: String(group.id),
            label: group.groupName
        }
    })
}
import { useState, useCallback } from "react";
import useErrorStore from "../components/Errors/store/error";


export default function useApiRequest<T>(
    apiMethod: (...args: any[]) => Promise<T>
) {
    const [data, setData] = useState<T | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const setErrorStore = useErrorStore(store=> store.setError)
    const execute = useCallback(async (...args: any[]) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await apiMethod(...args);
            setData(response);
        } catch (err: any) {
            setError(err?.response?.data?.message || err.message || "Unknown error");
            setErrorStore(err)
        } finally {
            setIsLoading(false);
        }

    }, [apiMethod]);

    return { data, error, isLoading, execute };
}

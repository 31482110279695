import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import PageLayout from '../../layouts/PageLayout/PageLayout';
import useApiRequest from '../../hooks/UseApiRequestState';
import UsersService from '../Players/api/UsersService';
import PlayerService from './api/PlayerService';
import Loader from '../../UI/Loader/Loader';
import styles from './Player.module.css';
import PlayerModule from '../../modules/PlayerModule/PlayerModule';

const Player = () => {
  
  return (
    <PlayerModule action='edit'/>
  )
}

export default Player

import React, { FC, useEffect, useState } from 'react';
import styles from './UserApplications.module.css';
import { IApliacation } from '../../../../models/IAplication';
import PlayerService from '../../api/PlayerService';
import { useNavigate } from 'react-router-dom';
import { formatDateRangeWithTimezone } from '../../../../helpers/formatDateRange';
import MyButton from '../../../../UI/MyButton/MyButton';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useWindowWidth } from '../../../../hooks/useWindowWidth';

interface UserApplicationsProps {
    userId: number;
}

export const UserApplications: FC<UserApplicationsProps> = ({ userId }) => {
    const [applications, setApplications] = useState<IApliacation[]>([]);
    const [currentPage, setCurrentPage] = useState(1); // Текущая страница
    const [totalPages, setTotalPages] = useState(0); // Общее количество страниц
    const [isLoading, setIsLoading] = useState(false); // Состояние загрузки
    const navigate = useNavigate();
    const itemsPerPage = 5; // Количество записей на странице

    const fetchApplications = async (page: number) => {
        setIsLoading(true);
        try {
            const { data } = await PlayerService.getUserApplications(userId, page, itemsPerPage);
            console.log(data)
            setApplications(data.data);
            setTotalPages(Math.ceil(data.total / itemsPerPage)); // Рассчитываем общее количество страниц
        } catch (error) {
            console.error('Ошибка загрузки данных:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        if (page < 1 || page > totalPages) return;
        setCurrentPage(page);
        fetchApplications(page); // Загружаем данные для новой страницы
    };



    useEffect(() => {
        fetchApplications(currentPage);
    }, []);

    function isDateNotLaterThanNow(date: Date): boolean {
        return moment(date).isSameOrBefore(moment());
    }

    const width = useWindowWidth()
    if (applications.length == 0) {
        return <></>;
    }
    return (
        <div className={styles.applications}>
            <div className={styles.title}>Anmeldungen:</div>
            <div className={styles.applicationsTitles}>
                <span className={styles.info}>Trainingszeit</span>
                <span>Trainig Info</span>
            </div>

            {isLoading ? (
                <div>Loading...</div>
            ) : (
                <div className={styles.applicationRow}>
                    {applications.map((application) => (
                        <div key={application.id} className={styles.applicationItem}>
                            <div className={styles.applicationItemRow}>
                                <span > 
                                    {formatDateRangeWithTimezone(application.startDate, application.endDate)}
                                </span>
                                <span className={styles.info}>{ application.group.groupName + ' ' + application.location.locationName}</span>
                                
                                    <span
                                        style={{ color: application.isPresent ? 'green' : 'red' }}
                                    >
                                        {isDateNotLaterThanNow(application.startDate) ?
                                        application.isPresent
                                            ? 'Anwesend beim Training'
                                            : 'Nicht beim Training anwesend' : ''}
                                        
                                    </span>
                                
                            </div>

                            <div className={styles.applicationButtonRow}>
                                <Link to={'/edit-training/' + application.trainingDatesId}>
                                    <MyButton className={styles.applicationButton}>
                                        {width >= 1100 ? 'Geh zum Training' : 'Training'}
                                    </MyButton>
                                </Link>
                                <Link to={'/application/' + application.trainingDatesId}>
                                    <MyButton
                                        className={styles.applicationButton}
                                    >
                                        {width >= 1100 ? 'Geh zum Anmeldung' : 'Anmeldung'}
                                    </MyButton>
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Пагинация */}
            <div className={styles.pagination}>
                <MyButton
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                    className={styles.paginationButton}
                >
                    Zurück
                </MyButton>
                <span>
                    Seite {currentPage} von {totalPages}
                </span>
                <MyButton
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                    className={styles.paginationButton}
                >
                    Weiter
                </MyButton>
            </div>
        </div>
    );
};

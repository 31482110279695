import React, { useState } from 'react';
import styles from './LocationMenu.module.css';
import MyInput from '../../../../UI/MyInput/MyInput';
import useLocationStore from '../../store/locations';
import MyButton from '../../../../UI/MyButton/MyButton';

const LocationMenu = () => {
    const locationName = useLocationStore(store => store.locationName);
    const setLocationName = useLocationStore(store => store.setLocationName);
    const locationUrl = useLocationStore(store => store.locationUrl);
    const setLocationUrl = useLocationStore(store => store.setLocationUrl);
    const createLocation = useLocationStore(store => store.createLocation);
    const editMode = useLocationStore(store => store.editMode);
    const editLocation = useLocationStore(store => store.editLocation);
    const setEditMode = useLocationStore(store => store.setEditMode);
    const clearFields = useLocationStore(store => store.clearFields);

    const [locationNameError, setLocationNameError] = useState<boolean>(false);
    const [locationUrlError, setLocationUrlError] = useState<boolean>(false);

    const handleCreate = async () => {
        setEditMode(false);
        const isLocationNameEmpty = locationName.trim() === '';
        const isLocationUrlEmpty = locationUrl.trim() === '';

        setLocationNameError(isLocationNameEmpty);
        setLocationUrlError(isLocationUrlEmpty);

        if (isLocationNameEmpty || isLocationUrlEmpty) return;

        await createLocation();
    };

    const handleEdit = async () => {
        await editLocation();
        await setEditMode(false);
        await clearFields();
    };

    return (
        <div className={styles.main}>
            <MyInput
                setValue={setLocationName}
                value={locationName}
                placeholder='Name der Location'
                type='text'
                hasError={locationNameError}
                className={styles.input}

            />
            <MyInput
                setValue={setLocationUrl}
                value={locationUrl}
                placeholder='Link zur Location'
                type='text'
                hasError={locationUrlError}
                className={styles.input}
            />
            <div className={styles.buttonRow}>
                {editMode && (
                    <MyButton className={styles.button} onClick={handleEdit}>
                        Bearbeiten
                    </MyButton>
                )}
                <MyButton className={styles.button} onClick={handleCreate}>
                    Erstellen
                </MyButton>
            </div>
        </div>
    );
};

export default LocationMenu;

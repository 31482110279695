// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomEvent_event__3f-x9 {
    font-family: 'Poppins';
    font-size: 14px;
    height: 50px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px;
    justify-content: space-between;
}
.CustomEvent_date__XHPK8{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.CustomEvent_title__SnFhH,.CustomEvent_date__XHPK8 {
    height: auto;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;


    word-wrap: break-word; /* Поддерживается всеми браузерами */
    overflow-wrap: break-word; /* Для современных браузеров */
    white-space: normal; /* Обычное поведение переноса строк */
}

.CustomEvent_applicationCount__01E1h{
    font-weight: 600;
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/MyCalendar/components/CustomEvent/CustomEvent.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;IACf,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,8BAA8B;AAClC;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;AACA;IACI,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;;;IAGjB,qBAAqB,EAAE,oCAAoC;IAC3D,yBAAyB,EAAE,8BAA8B;IACzD,mBAAmB,EAAE,qCAAqC;AAC9D;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".event {\n    font-family: 'Poppins';\n    font-size: 14px;\n    height: 50px;\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    padding: 0px;\n    justify-content: space-between;\n}\n.date{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n.title,.date {\n    height: auto;\n    font-family: 'Poppins';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 12px;\n    line-height: 12px;\n\n\n    word-wrap: break-word; /* Поддерживается всеми браузерами */\n    overflow-wrap: break-word; /* Для современных браузеров */\n    white-space: normal; /* Обычное поведение переноса строк */\n}\n\n.applicationCount{\n    font-weight: 600;\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"event": `CustomEvent_event__3f-x9`,
	"date": `CustomEvent_date__XHPK8`,
	"title": `CustomEvent_title__SnFhH`,
	"applicationCount": `CustomEvent_applicationCount__01E1h`
};
export default ___CSS_LOADER_EXPORT___;

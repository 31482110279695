import React from 'react'
import PopUpMenu from '../../UI/PopUpMenu/PopUpMenu'
import useErrorStore from './store/error'
import { useNavigate } from 'react-router-dom'
import styles from './Errors.module.css'

const Errors = () => {
  const errorStatus = useErrorStore(store => store.errorStatus)
  const clearError = useErrorStore(store => store.clearError)
  const errorCode = useErrorStore(store => store.errorCode)
  const errorMessage = useErrorStore(store => store.errorMessage)

  const handleBack = () => {
    clearError()
  }
  return (
    <div className={styles.main}>
      <PopUpMenu title={errorCode !== 500 ? 'Warnung' : 'Interner Serverfehler'} closeButtonTitle='ok' show={errorStatus} setShow={handleBack}>
        <div className={styles.text}>
          {errorMessage}
        </div>
      </PopUpMenu>
    </div>
  )
}

export default Errors

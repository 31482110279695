import React from 'react'
import PageLayout from '../../layouts/PageLayout/PageLayout'
import LocationMenu from './components/SearchMenu/LocationMenu'
import LocationsList from './components/LocationsList/LocationsList'
import styles from './LocationManager.module.css'
const LocationManager = () => {
  return (
    <PageLayout title='Standortverwaltung' centerTitle>
    <div className={styles.main}>
      <LocationMenu />
      <LocationsList/>
    </div>
  </PageLayout>
  )
}

export default LocationManager

import moment from "moment";

export function formatDateRangeWithTimezone(
    startDate: Date,
    endDate: Date
): string {
    // Преобразуем даты в указанный часовой пояс
    const start = moment(startDate).tz('Europe/Berlin');
    const end = moment(endDate).tz('Europe/Berlin');

    // Форматирование дат
    const formattedStart = start.format('DD.MM.YY HH:mm');
    const formattedEnd = end.format('HH:mm');

    // Возврат отформатированной строки
    return `${formattedStart}-${formattedEnd}`;
}

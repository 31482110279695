import { AxiosResponse } from "axios";
import $api from "../../../app/api/http";
import { IUser } from "../../../models/IUser";

export default class UsersService {
     static async searchUsers(searchQuery:String,userType:string):Promise<AxiosResponse<IUser[]>>{

        return await $api.get(`user/search-players?searchQuery=${searchQuery}&role=${userType}`);
    }

    static async convertPlayerState(userId:number):Promise<AxiosResponse<IUser>>{
        return await $api.put(`user/convert/new-to-regular/${userId}`);
    }
    
}
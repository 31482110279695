import moment from "moment";

export const isSameDay = (date1: string, date2: string): boolean => {
  // Преобразуем строки в объекты moment
  const momentDate1 = moment(date1);
  const momentDate2 = moment(date2);

  // Сравниваем год, месяц и день
  return momentDate1.isSame(momentDate2, "day");
};

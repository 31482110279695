// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppLayout_main__FzjJ2{
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: row;
}

`, "",{"version":3,"sources":["webpack://./src/layouts/AppLayout/AppLayout.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,aAAa;IACb,mBAAmB;AACvB","sourcesContent":[".main{\n    width: 100vw;\n    height: 100vh;\n    overflow: hidden;\n    display: flex;\n    flex-direction: row;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `AppLayout_main__FzjJ2`
};
export default ___CSS_LOADER_EXPORT___;

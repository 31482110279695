import React, { FC } from 'react';
import styles from './ColorInput.module.css';

interface ColorInputProps {
  value: string;
  setValue: (value: string) => void;
  placeholder?: string;
  className?: string;
  hasError?: boolean
}

const ColorInput: FC<ColorInputProps> = ({ hasError, value, setValue, placeholder,className }) => {
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <div className={`${styles.inputContainer}  ${hasError ? styles.errorBorder : ""} ${className? className: ''}`}>
      <input
        type="text"
        value={value}
       /*  onChange={handleTextChange} */
        placeholder={placeholder}
        className={styles.textInput}
      />
      <input
        type="color"
        value={value}
        onChange={handleColorChange}
        className={styles.colorInput}
      />
       {hasError && (
                <span className={styles.errorText}>Eingabefehler. Bitte überprüfen Sie die Daten.</span>
            )}
    </div>
  );
};

export default ColorInput;

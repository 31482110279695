import React, { useState } from 'react'
import MyButton from '../../../../UI/MyButton/MyButton'
import PopUpMenu from '../../../../UI/PopUpMenu/PopUpMenu'
import { useAsyncError, useParams } from 'react-router-dom'
import MyInput from '../../../../UI/MyInput/MyInput'
import styles from './ChangePassword.module.css'
import PlayerService from '../../api/PlayerService'
export const ChangePassword = () => {
    const {id} = useParams()
    const [password, setPassword] = useState<string>('');
    const [passwordRep, setPasswordRep] = useState<string>('')
    const [isShow, setIsShow] = useState<boolean>(false);
    const [repetitionErrror, setRepetitionErrror] = useState<boolean>(false)
    const handleChangePassword = async() => {
        if(checkRepetition()) {
            setRepetitionErrror(false)
            try{
                await PlayerService.changePassword(password, Number(id));
                alert('Das Passwort wurde erfolgreich geändert')
            }catch(error:any){
                console.log(error)
            }
             
        }else{
            setRepetitionErrror(true)
        }
    }

    const checkRepetition = ():boolean =>{
        return password == passwordRep;
    }
    return (
        <>
            <MyButton onClick={() => setIsShow(true)} className={styles.button}>
                Kennwort ändern
            </MyButton>
            <PopUpMenu
                setShow={setIsShow}
                show={isShow}
                onCloseButtonClick={() => setIsShow(false)}
                closeButtonTitle='Schliessen'
                title='Kennwort ändern'
                submitButtonTitle='Kennwort ändern'
                onSubmit={handleChangePassword}
            >
                <MyInput setValue={setPassword} value={password}
                    className={styles.input} placeholder='Bitte geben Sie Ihr Passwort ein' type='' />
                <MyInput setValue={setPasswordRep} value={passwordRep}
                    className={styles.input} placeholder='Bitte geben Sie Ihr Passwort erneut ein' type='' />
                    {repetitionErrror &&
                        <div className={styles.errorText}> Die Passwörter stimmen nicht überein</div>
                    }
            </PopUpMenu>
        </>
    )
}

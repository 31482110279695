import React from 'react'
import TrainingManager from '../../modules/TrainigManager/TrainingManager'

const AddTraining = () => {
  return (
      <TrainingManager action='create'/>
  )
}

export default AddTraining
